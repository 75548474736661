//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { documentBillAudit } from '@/api/modular/fileStream/documentManage'
import { userId } from '@/views/fileStream/documentManage/weixin/selectPerson'
import processMess from '@/views/fileStream/documentSend/components/process.vue'
export default {
  props: {
    params: {
      type: Object,
      default() {
        return {}
      },
    },
    billMess: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  components: {
    processMess,
  },
  data() {
    return {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 6 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 18 },
      },
      form: this.$form.createForm(this),
    }
  },
  computed: {
    approvalFlag() {
      const { documentNodeList } = this.billMess
      let nodeInfo =
        documentNodeList &&
        documentNodeList
          .filter((item) => item.sort != -1 && item.sort != 99 && item.sort != 100)
          .find((item) => item.progress == 'Y')
      let flagInfo =
        nodeInfo &&
        nodeInfo?.nodes
          .filter((item) => {
            return item.userId == userId
          })
          .find((item) => item.approvalFlag == 0)
      if (flagInfo) {
        let approvalFlag = flagInfo?.approvalFlag
        return +approvalFlag == 0
      }
    },
  },

  methods: {
    handleRadioChage({ target: { value } }) {
      this.form.setFieldsValue({
        nbyj: value === '2' ? '駁回' : '已閱',
      })
    },
    handleSubmit(e) {
      e.preventDefault()
      this.form.validateFields((err, values) => {
        console.log('Received values of form: ', values)
        if (!err) {
          const { approvalId, nodeId, id: billId } = this.params
          documentBillAudit({
            approvalId,
            billId,
            nodeId,
            opinion: values.nbyj,
            status: Number(values.spfs),
          })
            .then((res) => {
              if (res.code === 200) {
                this.$message.success(values.nbyj === '2' ? '流程終止成功' : '審批成功')
              } else {
                this.$message.error(res.message)
              }
              this.$nextTick(() => {
                this.$router.push({ name: 'fileStreamDocumentApproval', replace: true })
              })
            })
            .catch((err) => {
              console.log(err)
            })
        }
      })
    },
  },
}
